import { Vue, Component } from 'vue-property-decorator';
import Logo from '@/components/common/logo/Logo.vue';
import FacebookIcon from '@/assets/icons/facebook.svg?inline';
// eslint-disable-next-line import/extensions
import InstagramIcon from '@/assets/icons/instagram.svg?inline';
// eslint-disable-next-line import/extensions
import LinkedInIcon from '@/assets/icons/linkedin.svg?inline';
import { email } from '../../../../whitelabel.config';

@Component({
  components: {
    Logo,
    FacebookIcon,
    InstagramIcon,
    LinkedInIcon,
  },
})

export default class FooterExpanded extends Vue {
  email = email;
}
