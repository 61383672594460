import { Vue, Component, Watch } from 'vue-property-decorator';
import { Action, Getter, State as StateClass } from 'vuex-class';
import { Slide } from 'vue-burger-menu';
import detectEthereumProvider from '@metamask/detect-provider';
import { State, BlockchainStateSlice } from '@/store/models';
import Logo from '@/components/common/logo/Logo.vue';
import NavbarLinks from '@/components/common/navbar/links/NavbarLinks.vue';
import DropdownMenu from '@/components/common/navbar/dropdown/DropdownMenu.vue';
import LanguagesAccount from '@/components/common/navbar/languages-account/button/LanguagesAccount.vue';
import LoginRegister from '@/components/common/navbar/login-register/LoginRegister.vue';
import ResponsiveMenu from '@/components/common/navbar/responsive/ResponsiveMenu.vue';
import ResponsiveLinks from '@/components/common/navbar/responsive/links/ResponsiveLinks.vue';
import isAuthPageHelper from '@/helpers/isAuthPageHelper';

@Component({
  components: {
    Slide,
    Logo,
    NavbarLinks,
    DropdownMenu,
    ResponsiveLinks,
    LanguagesAccount,
    LoginRegister,
    ResponsiveMenu,
  },
})

export default class Navbar extends Vue {
  @StateClass auth!: State['auth'];
  @StateClass misc!: State['misc'];
  @StateClass authModal!: State['authModal'];
  @StateClass user!: State['user'];
  @StateClass blockchain!: BlockchainStateSlice;
  @Getter isUserLoggedIn!: State['isUserLoggedIn'];
  @Getter isModalOpen!: boolean;
  @Action openModal!: Function;

  isSliderOpen: boolean = false;
  wrongMetamaskAccountConnected: boolean = false;
  correctMetamaskAccountCounter = 0;

  @Watch('isModalOpen')
  onAuthActiveChange(isModalOpen): void {
    if (isModalOpen) {
      this.isSliderOpen = true;
      setTimeout((): void => {
        this.isSliderOpen = false;
      }, 150);
    }
  }

  @Watch('blockchain', { immediate: true, deep: true })
  async onBlockchainUpdate(newBlockchainData): Promise<void> {
    const providerDetected = await detectEthereumProvider();
    if (providerDetected) {
      // Check if a metamask account is connected
      // @ts-ignore
      window.ethereum.request({ method: 'eth_accounts' }).then(
        (accounts): void => {
          if (accounts?.length > 0) {
            const investorMetamaskAccountAddress = accounts[0];
            // eslint-disable-next-line no-console
            console.log('investorMetamaskAccountAddress ', investorMetamaskAccountAddress);
            if (
              this.user
              && this.user.investorMetamaskAccountAddress
              && this.user.investorMetamaskAccountAddress !== investorMetamaskAccountAddress
            ) {
              this.wrongMetamaskAccountConnected = true;
            } else if (this.user
              && this.user.investorMetamaskAccountAddress
              && this.user.investorMetamaskAccountAddress === investorMetamaskAccountAddress) {
              if (this.wrongMetamaskAccountConnected) {
                this.correctMetamaskAccountCounter = 3;
                setInterval((): void => {
                  this.correctMetamaskAccountCounter--;
                }, 1000);

                setTimeout((): void => {
                  this.wrongMetamaskAccountConnected = false;
                  this.correctMetamaskAccountCounter = 0;
                }, 3000);
              } else {
                this.wrongMetamaskAccountConnected = false;
              }
            }
          }
        },
      );
    }
  }

  async changeMetamaskWallet(): Promise<void> {
    // @ts-ignore
    await window.ethereum.request({
      method: 'wallet_requestPermissions',
      params: [{
        eth_accounts: {},
      }],
    });
  }

  get brand(): string {
    return require('../../../../whitelabel.config').name;
  }

  /* get myBrand(): string {
    return `${capitalize(this.$t('common.my') as string)} ${capitalize(this.brand)}`;
  } */

  get isLanding(): boolean {
    return this.$route.fullPath.startsWith('/landing');
  }

  get isAuthPage(): boolean {
    return isAuthPageHelper(this.$route);
  }
}
